<!--
 * @Descripttion: 
 * @version: 
 * @Author: 王超
 * @Date: 2021-08-31 00:47:01
 * @LastEditors: zhangming
 * @semesterFirst: 2021-08-31 00:47:01
-->
<template>
  <div class="foundation_page">
    <div style="font-size: 16px; color: #ba3037; padding: 30px 0 20px 0">
      成绩只需填写数字，如测试项目为视力，左右眼的两个成绩全部填写完成才会提交，填写所有测试成绩提交后则不可修改成绩
    </div>
    <div class="custTab">
      <!-- 提示框 -->
      <div class="tips-list" v-if="isTips">
        <ul>
          <li>
            <span class="tips-name">测试项目</span>
            <span class="tips-txt">{{ tipsContent.name }}</span>
          </li>
          <li>
            <span class="tips-name">测试器材</span>
            <span class="tips-txt">{{ tipsContent.equipment }}</span>
          </li>
          <li>
            <span class="tips-name">测试规则</span>
            <span class="tips-txt">{{ tipsContent.rule }}</span>
          </li>
          <li>
            <span class="tips-name">测试要求</span>
            <span class="tips-txt">{{ tipsContent.require }}</span>
          </li>
        </ul>
      </div>
      <table class="layui-table" lay-skin="nob">
        <thead>
          <tr>
            <th>幼儿编号</th>
            <th>姓名</th>
            <th>性别</th>
            <th
              :class="isClass === 0 ? 'active' : ''"
              @mouseover="bindmove(0)"
              @mouseout="bindout"
            >
              身高/cm
            </th>
            <th
              :class="isClass === 1 ? 'active' : ''"
              @mouseover="bindmove(1)"
              @mouseout="bindout"
            >
              体重/kg
            </th>
            <th
              :class="isClass === 2 ? 'active' : ''"
              @mouseover="bindmove(2)"
              @mouseout="bindout"
            >
              左眼视力
            </th>
            <th
              :class="isClass === 3 ? 'active' : ''"
              @mouseover="bindmove(3)"
              @mouseout="bindout"
            >
              右眼视力
            </th>
            <th
              :class="isClass === 4 ? 'active' : ''"
              @mouseover="bindmove(4)"
              @mouseout="bindout"
            >
              臂展/cm
            </th>
            <th
              :class="isClass === 5 ? 'active' : ''"
              @mouseover="bindmove(5)"
              @mouseout="bindout"
            >
              肺活量/ml
            </th>
            <th
              :class="isClass === 6 ? 'active' : ''"
              @mouseover="bindmove(6)"
              @mouseout="bindout"
            >
              胸围/cm
            </th>
            <!-- <th
              :class="isClass === 7 ? 'active' : ''"
              @mouseover="bindmove(7)"
              @mouseout="bindout"
            >
              体脂率/%
            </th> -->
            <th
              :class="isClass === 8 ? 'active' : ''"
              @mouseover="bindmove(8)"
              @mouseout="bindout"
              style="width: 130px"
            >
              坐姿体前屈/cm
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list" :key="item.id">
            <td class="">
              S{{ common.PrefixZero(index + 1 + (params.page - 1) * 10, 4) }}
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.sex }}</td>
            <td>
              <input
                class="inpsty"
                v-model="item.values['1'].value"
                :disabled="item.values['1'].status === '1'"
                maxlength="8"
                @change="handleInput(item, item.values['1'].value, index, '1')"
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
            </td>
            <td>
              <input
                class="inpsty"
                v-model="item.values['2'].value"
                :disabled="item.values['2'].status === '1'"
                maxlength="8"
                @change="handleInput(item, item.values['2'].value, index, '2')"
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
            </td>
            <td>
              <input
                class="inpsty"
                v-model="item.values['left'].value"
                :disabled="item.values['left'].status === '1'"
                maxlength="8"
                @change="
                  handleInput(
                    item,
                    item.values['left'].value,
                    index,
                    '3',
                    'left'
                  )
                "
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
            </td>
            <td>
              <input
                class="inpsty"
                v-model="item.values['right'].value"
                :disabled="item.values['right'].status === '1'"
                maxlength="8"
                @change="
                  handleInput(
                    item,
                    item.values['right'].value,
                    index,
                    '3',
                    'right'
                  )
                "
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
            </td>
            <td>
              <input
                class="inpsty"
                v-model="item.values['4'].value"
                :disabled="item.values['4'].status === '1'"
                maxlength="8"
                @change="handleInput(item, item.values['4'].value, index, '4')"
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
            </td>
            <td>
              <input
                class="inpsty"
                v-model="item.values['5'].value"
                :disabled="item.values['5'].status === '1'"
                maxlength="8"
                @change="handleInput(item, item.values['5'].value, index, '5')"
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
            </td>
            <td>
              <input
                class="inpsty"
                v-model="item.values['6'].value"
                :disabled="item.values['6'].status === '1'"
                maxlength="8"
                @change="handleInput(item, item.values['6'].value, index, '6')"
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
            </td>
            <!-- <td>
              <input
                class="inpsty"
                v-model="item.values['7'].value"
                :disabled="item.values['7'].status === '1'"
                maxlength="8"
                @change="handleInput(item, item.values['7'].value, index, '7')"
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
            </td> -->
            <td>
              <input
                class="inpsty"
                v-model="item.values['8'].value"
                :disabled="item.values['8'].status === '1'"
                maxlength="8"
                @change="handleInput(item, item.values['8'].value, index, '8')"
                oninput="value=value.replace(/[^\d^\.^\-]+/g,'')"
              />
              <input
                v-if="index == list.length - 1"
                style="width: 0; height: 0; opacity: 0"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <comPagination :params="params" @changePage="changePage"></comPagination>
    </div>
    <!-- <div class="loadmore loadmore-line loadmore-nodata">
      <span class="loadmore-tips">暂无数据</span>
    </div> -->
    <!-- <div class="pages">{$bt->render()}</div> -->
    <div class="startc" style="margin-bottom: 20px">
      <!-- <div class="btn btn_start" @click="submit" :loading="true">提交</div> -->
      <el-button
        type="primary"
        class="btn btn_start"
        :loading="btnLoding"
        @click="submit"
        >提交</el-button
      >
    </div>
  </div>
</template>

<script>
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "foundation",
  components: { comPagination },
  data() {
    return {
      btnLoding: false,
      isTips: false, // 判断提示框显示
      isClass: "", // 判断类名
      list: [],
      params: {
        page: 0,
        size: 10,
        total: 0,
      },
      cacheName: "",
      tipsContent: "",
    };
  },
  created() {},
  mounted() {
    this.cacheName = `physiqueTest_schoolId=${this.$store.state.userInfo.schoolId}_teacherId=${this.$store.state.userInfo.teacherId}_isEnd=${this.$route.query.isEnd}_gradeId=${this.$route.query.gradeId}`;
    this.getList();
  },
  methods: {
    // 鼠标经过提示
    bindmove(val) {
      this.isTips = true;
      console.log(val);
      if (val == 0) {
        let tipsContent = {
          // 提示内容
          name: "身高/cm",
          equipment: "身高测量仪1个",
          rule: "被测量者赤脚，“立正”姿势站在身高计的底板上，脚跟、骶骨部及两肩胛 间紧靠身高计的立柱上。测量者站在被测量人的左右均可，将其头部调整到 耳屏上缘与眼眶下缘的最低点齐平，再移动身高计的水平板至被测量人的头 顶，使其松紧度适当，即可测量出身高",
          require: "测量 1 次，以厘米为单位，具体到小数点一位，例121.1厘米。",
        };
        this.tipsContent = tipsContent;
        this.isClass = val;
      } else if (val == 1) {
        let tipsContent = {
          // 提示内容
          name: "体重/kg",
          equipment: "体重测量仪1个",
          rule: "被测量者赤脚轻装站在体重测量仪上，根据测量仪显示的数字，即可测量出 体重。",
          require: "测量 1 次，以千克为单位，具体到小数点一位，例22.5千克",
        };
        this.tipsContent = tipsContent;
        this.isClass = val;
      } else if (val == 2) {
        let tipsContent = {
          // 提示内容
          name: "视力",
          equipment: "视力表1个",
          rule: "被测量者站在距视力表5米的位置，两眼分别检查，先查右眼，后左眼。查一 眼时，须以遮板将另一眼完全遮住，但勿压迫眼球。 检查时，受检者从上至下指出“E”字形视标开口的方向，记录所能看清的最 小一行视力读数，即为该眼的远视力。倘若对某行标记部分能够看对，部分 认不出，如果看得清的标记数多于看不清的标记数，那么该行视力读数作为 被测眼的远视力，否则将上一行作为被测眼的远视力。",
          require:
            "左右眼分别测量 1 次，国际标准对数视力表上视力的范围是0.1至2.0，相对 应的对数视力范围为4.0至5.3。",
        };
        this.tipsContent = tipsContent;
        this.isClass = val;
      } else if (val == 3) {
        let tipsContent = {
          // 提示内容
          name: "视力",
          equipment: "视力表1个",
          rule: "被测量者站在距视力表5米的位置，两眼分别检查，先查右眼，后左眼。查一 眼时，须以遮板将另一眼完全遮住，但勿压迫眼球。 检查时，受检者从上至下指出“E”字形视标开口的方向，记录所能看清的最 小一行视力读数，即为该眼的远视力。倘若对某行标记部分能够看对，部分 认不出，如果看得清的标记数多于看不清的标记数，那么该行视力读数作为 被测眼的远视力，否则将上一行作为被测眼的远视力。",
          require:
            "左右眼分别测量 1 次，国际标准对数视力表上视力的范围是0.1至2.0，相对 应的对数视力范围为4.0至5.3。",
        };
        this.tipsContent = tipsContent;
        this.isClass = val;
      } else if (val == 4) {
        let tipsContent = {
          // 提示内容
          name: "臂展/cm",
          equipment: "米尺1个",
          rule: "用米尺测量两臂侧向最大限度地水平伸展时，两中指指尖点之间的直线距离。",
          require: "测量 1 次，以厘米为单位，具体到小数点一位，例121.1厘米。",
        };
        this.tipsContent = tipsContent;
        this.isClass = val;
      } else if (val == 5) {
        let tipsContent = {
          // 提示内容
          name: "肺活量/ml",
          equipment: "肺活量测量仪1个",
          rule: "尽力吸气后，再尽力朝肺活量测量仪呼出全部气体。",
          require: "测量 1 次，以毫升为单位，具体到整数，例751毫升。",
        };
        this.tipsContent = tipsContent;
        this.isClass = val;
      } else if (val == 6) {
        let tipsContent = {
          // 提示内容
          name: "胸围/cm",
          equipment: "米尺1个",
          rule: "将米尺放在胸前位置测量围绕身体一周的距离。",
          require: "	测量 1 次，以厘米为单位，具体到小数点一位，例35.5厘米。",
        };
        this.tipsContent = tipsContent;
        this.isClass = val;
      } else if (val == 7) {
        let tipsContent = {
          // 提示内容
          name: "体脂率/%",
          equipment: "体脂测量仪1个",
          rule: "被测量者赤脚轻装站在体脂测量仪上，根据测量仪显示的结果，即可测量出 身体肌肉和脂肪的比例。",
          require:
            "体脂率=（脂肪重量/体重）*100%。测量 1 次，具体到1%，例40岁以下的成 年人，男性体脂的正常范围是14%~20%，而女性稍高一些，为17%~24%。",
        };
        this.tipsContent = tipsContent;
        this.isClass = val;
      } else if (val == 8) {
        let tipsContent = {
          // 提示内容
          name: "坐姿体前屈/cm",
          equipment: "坐位体前屈测量仪1个",
          rule: "受试者坐在连接于箱体的软垫上，两腿伸直，不可弯曲，脚跟并拢，脚尖分 开约10—15厘米，踩在测量计垂直平板上，两手并拢。 两臂和手伸直，渐渐使上体前屈，用两手中指尖轻轻推动标尺上的游标前滑 （不得有突然前伸动作），直到不能继续前伸时为止。 测试计的脚蹬纵板内沿平面为0点，向前为正值，向后为负值。",
          require:
            "测量 1 次，以厘米为单位，取小数点后一位。如为正值则在数值前加“+” 符号，负值则加“－”符号。",
        };
        this.tipsContent = tipsContent;
        this.isClass = val;
      }
    },
    // 鼠标离开隐藏
    bindout() {
      this.isTips = false;
      this.tipsContent = "";
      this.isClass = "";
    },
    // 获取表格列表接口
    getList() {
      let data = {
        gradeId: this.$route.query.gradeId,
        gradeType: this.$route.query.gradeType,
        isEnd: this.$route.query.isEnd,
        province: this.$store.state.schoolInfo.atteSchool.province,
        schoolId: this.$route.query.schoolId,
        semesterId: this.$route.query.semesterId,
        teacherId: this.$route.query.teacherId,
        // testlevel: this.$route.query.testlevel,
        type: "0",
        stuIds: this.$route.query.stuIds,
        // sportId: this.$route.query.sportId,
      };
      this.api.pes.findStuBodyTestByGradeId(this.params, data).then((res) => {
        this.params.total = res.data.total;
        this.list = res.data.list[0].data;
        //处理缓存数据
        this.formatCacheData();
        this.list.forEach((item) => {
          if (
            item.values["3"].value &&
            item.values["3"].value.indexOf("/") != -1
          ) {
            let a1 = item.values["3"].value.split("/")[0];
            let a2 = item.values["3"].value.split("/")[1];
            this.$set(item.values, "left", {});
            this.$set(item.values, "right", {});
            this.$set(item.values["left"], "value", a1);
            this.$set(item.values["left"], "status", item.values["3"].status);
            this.$set(item.values["right"], "value", a2);
            this.$set(item.values["right"], "status", item.values["3"].status);
          } else {
            this.$set(item.values, "left", {});
            this.$set(item.values, "right", {});
            this.$set(item.values["left"], "value", "");
            this.$set(item.values["left"], "status", "0");
            this.$set(item.values["right"], "value", "");
            this.$set(item.values["right"], "status", "0");
          }
          for (let name in item.values) {
            if (name != "8") {
              if (item.values[name].value == 0) item.values[name].value = "";
            }
          }
        });
        this.params.total = res.data.total;
      });
    },
    toTest() {
      this.$router.push({
        path: "/testCheck",
        query: {
          gradeId: this.$route.query.id,
          isSports: false,
        },
      });
    },
    changePage(val) {
      this.params.page = val;
      this.getList();
    },
    handleInput(item, val, ind, key, eyeItem) {
      //身高
      // var numReg = /^[0-9]*$/;
      // var numRe = new RegExp(numReg);
      var judge = true;
      let fIndex = val.indexOf(".");
      let indexs = val.split(".");
      if (fIndex == 0) {
        judge = false;
      } else if (fIndex > 0 && fIndex == val.length - 1) {
        judge = false;
      } else if (indexs.length > 2) {
        judge = false;
      }

      let eIndex = val.indexOf("-");
      let eIndexs = val.split("-");
      if (eIndex > 0) {
        judge = false;
      } else if (eIndex == 0) {
        if (val.length == 1) {
          judge = false;
        } else if (eIndexs.length > 2) {
          judge = false;
        }
      }
      if (!judge) {
        item.values[key].value = val = "";
        this.$confirm("超出测试范围", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        return;
      }
      if (key == 1 && val) {
        if (parseFloat(val) < 50 || parseFloat(val) > 150) {
          judge = false;
        }
        let pointIndex = val.indexOf(".");
        if (pointIndex > 0 && val.length - pointIndex > 2) {
          item.values[key].value = val = val.substring(0, pointIndex + 2);
          // val.reportOne = item.values[key].value;
        }
        if (!judge) {
          item.values[key].value = val = "";
          this.$confirm("身高超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
        //体重
      } else if (key == 2 && val) {
        if (parseFloat(val) < 5 || parseFloat(val) > 45) {
          judge = false;
        }
        let pointIndex = val.indexOf(".");
        if (pointIndex > 0 && val.length - pointIndex > 2) {
          item.values[key].value = val = val.substring(0, pointIndex + 2);
        }
        if (!judge) {
          item.values[key].value = val = "";
          this.$confirm("体重超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
        // 左眼视力,右眼视力
      } else if (key == "3" && val) {
        if (parseFloat(val) < 4 || parseFloat(val) > 5.3) {
          judge = false;
        }
        let pointIndex = val.indexOf(".");
        if (pointIndex > 0 && val.length - pointIndex > 2) {
          val = val.substring(0, pointIndex + 2);
          this.$set(item.values[eyeItem], "value", val);
        }
        if (!judge) {
          item.values[eyeItem].value = val = "";
          this.$confirm("视力范围为4-5.3之间", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        } else {
          if (item.values.left.value && item.values.right.value) {
            item.values[key].value = val =
              item.values.left.value + "/" + item.values.right.value;
          } else {
            return;
          }
        }
        // 臂展
      } else if (key == "4" && val) {
        ind = 3;
        if (parseFloat(val) < 20 || parseFloat(val) > 180) {
          judge = false;
        }
        let pointIndex = val.indexOf(".");
        if (pointIndex > 0 && val.length - pointIndex > 2) {
          item.values[key].value = val = val.substring(0, pointIndex + 2);
        }
        if (!judge) {
          item.values[key].value = "";
          val = "";
          this.$confirm("臂展超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
        // 肺活量
      } else if (key == 5 && val) {
        ind = 4;
        if (parseFloat(val) < 100 || parseFloat(val) > 2000) {
          judge = false;
        }
        let pointIndex = val.indexOf(".");
        if (pointIndex > 0) {
          item.values[key].value = val = val.substring(0, pointIndex);
        }
        if (!judge) {
          item.values[key].value = val = "";
          this.$confirm("肺活量超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
        // 胸围
      } else if (key == 6 && val) {
        ind = 5;
        if (parseFloat(val) < 10 || parseFloat(val) > 110) {
          judge = false;
        }
        let pointIndex = val.indexOf(".");
        if (pointIndex > 0 && val.length - pointIndex > 2) {
          item.values[key].value = val = val.substring(0, pointIndex + 2);
        }
        if (!judge) {
          item.values[key].value = val = "";
          this.$confirm("胸围超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
        //体脂率
      } else if (key == 7 && val) {
        ind = 6;
        if (parseFloat(val) < 1 || parseFloat(val) > 40) {
          judge = false;
        }
        let pointIndex = val.indexOf(".");
        if (pointIndex > 0) {
          item.values[key].value = val = val.substring(0, pointIndex);
        }
        if (!judge) {
          item.values[key].value = val = "";
          this.$confirm("体脂率超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
        // 坐卧体前屈
      } else if (key == 8 && val) {
        if (parseFloat(val) < -50 || parseFloat(val) > 50) {
          judge = false;
        }
        let pointIndex = val.indexOf(".");
        if (pointIndex > 0 && val.length - pointIndex > 2) {
          item.values[key].value = val = val.substring(0, pointIndex + 2);
        }
        if(val == 0) item.values[key].value = val = '0';
        if (!judge) {
          item.values[key].value = val = "";
          this.$confirm("坐卧体前屈超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
      }
      //缓存数据
      this.cacheData(item, ind, val, key);
      // if (reportOne && val.canPost) {
      //   if (val.canPost) {
      //     // val.canPost = false;
      //     let data = {
      //       type: item.atteStudent.type,
      //       stuId: parseInt(item.atteStudent.id),
      //       teacherId: this.$route.query.teacherId,
      //       testingFoundationId: parseInt(val.testingFoundationId),
      //       testlevel: "1",
      //       semesterId: parseInt(this.$route.query.semesterId),
      //       gradeId: parseInt(item.atteStudent.gradeId),
      //       isEnd: this.$route.query.isEnd,
      //       gradeType: this.$route.query.gradeType,
      //       schoolId: parseInt(this.$route.query.schoolId),
      //       province: this.$store.state.userInfo.provinces,
      //       data: {},
      //     };

      //     data.data[ind + 1] = reportOne;
      //     console.log(data);
      //     // this.api.pes.add(data).then((res) => {
      //     //   val.canPost = true;
      //     //   if (!res.flag) {
      //     //     val.reportOne = "";
      //     //     this.$confirm("上传失败，请重试", "提示", {
      //     //       confirmButtonText: "确定",
      //     //       cancelButtonText: "取消",
      //     //       type: "warning",
      //     //     });
      //     //   }
      //     // });
      //   } else {
      //     this.$confirm("存在上传异常，请重试", "提示", {
      //       confirmButtonText: "确定",
      //       cancelButtonText: "取消",
      //       type: "warning",
      //     });
      //   }
      // }
    },
    //缓存数据
    cacheData(item, index, val, key) {
      let exerciseTest = JSON.parse(localStorage.getItem(this.cacheName)) || {};
      if (exerciseTest.data) {
        if (exerciseTest.data[`page${this.params.page}`]) {
          //到page这级
          let flag = false;
          let curInd = 0;
          for (
            let i = 0;
            i < exerciseTest.data[`page${this.params.page}`].length;
            i++
          ) {
            if (
              exerciseTest.data[`page${this.params.page}`][i].stuId ===
              item.stuId
            ) {
              flag = true;
              curInd = i;
              break;
            }
          }
          if (flag) {
            //有对应的stuId 到values这级
            exerciseTest.data[`page${this.params.page}`][curInd].values[key] = {
              value: val,
              status: "0",
            };
            if (!val) {
              this.$delete(
                exerciseTest.data[`page${this.params.page}`][curInd].values,
                key
              );
            }
          } else {
            //没有对应的stuId
            let param = {
              stuId: item.stuId,
            };
            param.values = {};
            param.values[key] = { value: val, status: "0" };
            exerciseTest.data[`page${this.params.page}`].push(param);
          }
        } else {
          let param = {
            stuId: item.stuId,
          };
          param.values = {};
          param.values[key] = { value: val, status: "0" };
          if (!exerciseTest.data[`page${this.params.page}`]) {
            exerciseTest.data[`page${this.params.page}`] = [];
          }
          exerciseTest.data[`page${this.params.page}`].push(param);
        }
      } else {
        exerciseTest = {
          teacherId: this.$store.state.userInfo.teacherId,
          schoolId: this.$store.state.userInfo.schoolId,
          testingSportsId: this.$route.query.sportId,
          data: {},
        };
        exerciseTest.data[`page${this.params.page}`] = [];
        let param = {
          stuId: item.stuId,
        };
        param.values = {};
        param.values[key] = { value: val, status: "0" };
        exerciseTest.data[`page${this.params.page}`].push(param);
      }
      localStorage.setItem(this.cacheName, JSON.stringify(exerciseTest));
    },
    //处理缓存数据
    formatCacheData() {
      let exerciseTest = JSON.parse(localStorage.getItem(this.cacheName)) || {};
      if (
        exerciseTest.teacherId == this.$store.state.userInfo.teacherId &&
        exerciseTest.schoolId == this.$store.state.userInfo.schoolId
      ) {
        if (exerciseTest.data[`page${this.params.page}`]) {
          this.list.forEach((sItem) => {
            let cacheData = exerciseTest.data[`page${this.params.page}`].filter(
              (ssItem) => {
                return ssItem.stuId === sItem.stuId;
              }
            )[0];
            if (cacheData) {
              for (let name in sItem.values) {
                if (cacheData.values[name]) {
                  sItem.values[name].value = cacheData.values[name].value;
                }
              }
            }
          });
        }
      }
    },
    submit() {
      this.btnLoding = true;
      let exerciseTest = JSON.parse(localStorage.getItem(this.cacheName)) || {};
      let list = [];
      for (let name in exerciseTest.data) {
        list = list.concat(exerciseTest.data[name]);
      }
      if (!list.length) {
        this.$notice({
          type: "warning",
          message: "您已添加数据请勿重复提交",
        }).isShow();
        this.btnLoding = false;
        return;
      }
      let stuId = list
        .map((item) => {
          return item.stuId;
        })
        .join(",");
      let data = {
        gradeId: Number(this.$route.query.gradeId),
        gradeType: this.$route.query.gradeType,
        isEnd: this.$route.query.isEnd,
        province: this.$store.state.schoolInfo.atteSchool.province,
        schoolId: Number(this.$route.query.schoolId),
        semesterId: Number(this.$route.query.semesterId),
        teacherId: this.$route.query.teacherId,
        // testlevel: this.$route.query.testlevel,
        type: "0",
        stuIds: stuId,
        data: list,
      };
      console.log(list, "listlist");
      this.api.pes.addBodyData(data).then((res) => {
        if (res.flag) {
          this.btnLoding = false;
          this.$notice({
            type: "success",
            message: res.message,
          }).isShow();
          this.getList();
          localStorage.removeItem(this.cacheName);
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
          this.btnLoding = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.active:before {
  content: "";
  width: 0px;
  height: 0px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  position: absolute;
  top: 31px;
  left: 32px;
}
.tips-list {
  position: absolute;
  top: 39px;
  right: 0;
  width: 1050px;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
  border-radius: 4px;
  z-index: 9;
  ul {
    li {
      padding: 10px 20px;
      font-size: 16px;
      color: #888;
      display: flex;
      span {
        display: block;
      }
      .tips-name {
        width: 10%;
        color: #333;
      }
      .tips-txt {
        width: 90%;
        white-space: normal;
        word-wrap: break-word;
        word-break: break-all;
      }
    }
    li:nth-child(even) {
      background: #f7fbff;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/* 火狐浏览器样式清除 */
input[type="number"] {
  -moz-appearance: textfield;
}
.foundation_page {
  width: 100%;
  .custTab thead th,
  .custTab tbody td {
    text-align: center;
    font-size: 0.24rem !important;
  }
  .custTab thead th:first-child {
    border-radius: 4px 0 0 0;
  }
  .custTab thead th:last-child {
    border-radius: 0 4px 0 0;
  }
  .inpsty {
    width: 54px;
    height: 22px;
    padding: 0;
    line-height: 22px;
    font-size: 14px;
    text-align: center;
    background: #ba30371c;
    border-radius: 4px;
  }

  .startc {
    padding-right: 0.5rem;
    margin-bottom: 2rem;
  }

  .startc .btn {
    width: 100px;
    height: 38px;
    background: linear-gradient(232deg, #b93037, #ef525b);
    box-shadow: 0px 3px 6px 0px rgba(255, 67, 77, 0.33);
    border-radius: 6px;
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    line-height: 38px;
    text-align: center;
    border: none;
  }
  .startc .btn:hover {
    background: linear-gradient(232deg, #cf565b, #f46e73);
  }
  .tabTit {
    min-height: 0.9rem;
    box-sizing: border-box;
    /* text-align: right; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .tabTit .tips {
    font-size: 0.2rem;
    /* position: absolute;
    left: 0; */
  }

  .tabTit .text {
    line-height: 0.3rem;
    font-weight: 700;
    color: #cb1b31;
    font-size: 0.3rem;
    /* padding-right: .5rem;
    text-align: right;
    float: right; */
  }

  .tabTit .searchdiv {
    display: flex;
  }

  .tabTit .searchdiv .btn-search {
    margin-right: 0.2rem;
  }

  .tabTit .btn-add,
  .tabTit .btn-blue {
    padding: 0rem 0.4rem;
    height: 38px;
    line-height: 38px;
    font-size: 0.22rem;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
    border: none;
  }

  .tabTit .btn-add {
    background: #cb1b31;
    color: #fff;
  }

  .tabTit .btn-blue {
    background: #228cec;
    color: #fff;
  }
  .custTab {
    /* padding: 0rem .5rem; */
    position: relative;
    margin-bottom: 0.1rem;
  }

  .custTab .layui-table {
    padding: 0;
    margin: 0;
    width: 100%;
    table-layout: fixed;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
    border-radius: 4px;
  }

  .custTab thead {
    position: relative;
  }

  .custTab thead th {
    font-size: 16px !important;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    opacity: 0.9;
    background: #ba3037;
    height: 42px;
    padding: 0;
  }

  .custTab tbody tr {
    border-bottom: solid 1px #dddddd;
  }
  .custTab tbody tr:last-child {
    border-bottom: none;
  }
  .custTab tbody td {
    height: 42px;
    font-size: 14px !important;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    opacity: 0.9;
    padding: 0;
  }

  .custTab tbody tr td:last-child {
    padding-right: 0;
  }

  .custTab .btn-start {
    width: 1.28rem;
    height: 0.38rem;
    line-height: 0.38rem;
    background: #b5b5b5;
    color: #fff;
    display: inline-block;
    font-size: 0.22rem;
    text-align: center;
    transition: all 0.2s;
    float: right;
  }
  .custTab .btn-start:hover {
    background: #cc1a30;
  }

  .custTab .btn-play {
    // background: #cc1a30 url(../images/play2.png) 0.2rem center no-repeat;
    background-size: 0.26rem;
    height: 0.4rem;
    line-height: 0.4rem;
    padding-left: 0.6rem;
    width: 0.7rem;
    display: inline-block;
    border-radius: 0.25rem;
    color: #fff;
    font-size: 0.22rem;
  }
}
.dialog_block {
  display: flex;
  justify-content: center;
}
</style>
